import './index.css'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { store } from './reducers/store'
import { inject } from '@vercel/analytics'
import { theme, ConfigProvider } from 'antd'
import { AppRoutes } from './routes/AppRoutes'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './utils/auth/Auth'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from './i18n/i18nProvider'

const { defaultAlgorithm, darkAlgorithm } = theme

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  environment: process.env.REACT_APP_ENVIRONMENT,
  release: 'alela-admin@' + process.env.REACT_APP_VERSION,
})

// Enable Vercel analytics
inject({
  mode:
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'production'
      : 'development',
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <BrowserRouter basename="/">
    <Provider store={store}>
      <AuthProvider>
        <I18nProvider>
          <ConfigProvider
            theme={{
              algorithm: darkAlgorithm,
              token: {
                colorPrimary: '#6c7cff',
                colorBgBase: '#000000',
                colorBgLayout: '#1b1a1a',
                colorBgContainer: '#1E1E1E',
              },
            }}
          >
            <AppRoutes />
          </ConfigProvider>
        </I18nProvider>
      </AuthProvider>
    </Provider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
