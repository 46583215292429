import { SBModel } from '../../utils/helpers/crud/models'
import { SBRMType, SBRMTypeInfosnType } from './SBRMModel'

export const SBRMTypeInfos: SBRMTypeInfosnType<SBModel> = {
  [SBRMType.file]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    layout: {
      create: { rightPanelContent: 'form', rightPanelVisible: true },
      duplicate: { rightPanelContent: 'form', rightPanelVisible: true },
      update: {
        rightPanelContent: 'form',
        rightPanelVisible: true,
        leftPanelContent: 'file-viewer',
        leftPanelVisible: true,
      },
      view: {
        rightPanelVisible: false,
        leftPanelContent: 'file-viewer',
        leftPanelVisible: true,
      },
    },
    filePropertyName: null,
    reducerName: 'file',
  },
  [SBRMType.user]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    layout: {
      create: { rightPanelContent: 'form', rightPanelVisible: true },
      duplicate: { rightPanelContent: 'form', rightPanelVisible: true },
      update: { rightPanelContent: 'form', rightPanelVisible: true },
      view: { rightPanelContent: 'form', rightPanelVisible: true },
    },
    reducerName: 'user',
  },
  [SBRMType.role]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    layout: {
      create: { rightPanelContent: 'form', rightPanelVisible: true },
      duplicate: { rightPanelContent: 'form', rightPanelVisible: true },
      update: { rightPanelContent: 'form', rightPanelVisible: true },
      view: { rightPanelContent: 'form', rightPanelVisible: true },
    },
    reducerName: 'role',
  },
  [SBRMType.tenant]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: false,
    duplicable: false,
    layout: {
      create: { rightPanelContent: 'form', rightPanelVisible: true },
      duplicate: { rightPanelContent: 'form', rightPanelVisible: true },
      update: { rightPanelContent: 'form', rightPanelVisible: true },
      view: { rightPanelContent: 'form', rightPanelVisible: true },
    },
    reducerName: 'tenant',
  },
  [SBRMType.banner]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: true,
    layout: {
      create: { rightPanelContent: 'form', rightPanelVisible: true },
      duplicate: { rightPanelContent: 'form', rightPanelVisible: true },
      update: { rightPanelContent: 'form', rightPanelVisible: true },
      view: { rightPanelContent: 'form', rightPanelVisible: true },
    },
    reducerName: 'banner',
  },
}
