import { Alert, Avatar, Space, Typography } from 'antd'
import { useAppSelector } from '../../reducers/hooks'
import dayjs from 'dayjs'
import { selectTenantById } from '../../reducers/TenantReducer'
import { Tenant } from '../../models/Tenant'
import { ConditionalLinkWrapper } from '../utils/ConditionalWrappers'

const { Text } = Typography
interface Props {
  id?: number
  model?: Tenant
  linkToDetails?: boolean
}

export const TenantAvatar = ({ id, model, linkToDetails = true }: Props) => {
  const item = model ? model : useAppSelector(selectTenantById(id!))

  if (!item) {
    return <></>
  }

  return (
    <ConditionalLinkWrapper
      condition={linkToDetails}
      to={`/tenants/${item.id}`}
    >
      <Space direction={'horizontal'}>
        <Avatar shape="square" src={item.logo} size={'large'}>
          {item.name.slice(0, 1)}
        </Avatar>
        <Space.Compact direction="vertical">
          <Text>{item.name}</Text>
          <Text type="secondary">{item.id}</Text>
        </Space.Compact>
      </Space>
    </ConditionalLinkWrapper>
  )
}

export type TenantAvatarType = { Avatar: typeof TenantAvatar }
