import qs from 'qs'
import axios from 'axios'
import { Card, Form, Skeleton, Switch, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { SETTING_URL } from '../../utils/urls'

interface Props {}

const MaintenanceMode = ({}: Props) => {
  const [isOn, setIsOn] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const maintenanceModeKey = 'front_maintenance_mode'

  useEffect(() => {
    setIsFetching(true)
    axios
      .get(`${SETTING_URL}?${qs.stringify({ keys: [maintenanceModeKey] })}`)
      .then((response) => {
        if (response.data.data && response.data.data.length) {
          setIsOn(response.data.data[0].value)
        }
        setIsFetching(false)
      })
  }, [])

  return (
    <Card
      title="Maintenance mode"
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
    >
      {isFetching && <Skeleton title={false} />}
      {!isFetching && (
        <Form.Item label={'Maintenance mode is'} valuePropName="checked">
          <Switch
            checked={isOn}
            loading={isUpdating}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={() => {
              setIsUpdating(true)
              const nextValue = !isOn
              setIsOn(nextValue)
              axios
                .post(SETTING_URL, {
                  settings: [{ key: maintenanceModeKey, value: nextValue }],
                })
                .then((_) => {
                  setIsUpdating(false)
                })
            }}
          />
        </Form.Item>
      )}
    </Card>
  )
}

type MaintenanceModeType = { MaintenanceMode: typeof MaintenanceMode }

export { MaintenanceMode, type MaintenanceModeType }
