import { Alert, Button, Form, Input, Result } from 'antd'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { forgotPassword } from '../../utils/auth/requests'
import { emailRule, requiredRule } from '../../utils/rules'

const ForgotPasswordPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [data, setData] = useState({ email: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [hasError, setHasError] = useState(false)

  const onFinish = async () => {
    setIsLoading(true)
    setHasError(false)
    try {
      await forgotPassword(data.email)
      setIsSuccess(true)
    } catch (error) {
      setHasError(true)
      setIsLoading(false)
    }
  }

  return isSuccess ? (
    <Result
      status="success"
      title="Check your inbox"
      subTitle={
        <>
          And check also your spam hihi
          <br />
          <br />
          In case you have an account with us you will receive a password reset
          link
        </>
      }
      extra={[
        <Button
          key={'back-login'}
          type="primary"
          onClick={() => navigate('/auth/login')}
        >
          Back to login
        </Button>,
      ]}
    />
  ) : (
    <>
      <div style={{ textAlign: 'center' }}>
        <img
          src={'/assets/images/logocurve.png'}
          height={45}
          style={{ marginTop: 12, marginLeft: 'auto', marginRight: 'auto' }}
        />
      </div>
      <Form
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onValuesChange={(_, allFields) => setData(allFields)}
      >
        <Form.Item name="email" rules={[requiredRule(intl), emailRule(intl)]}>
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item>
          <Button
            className="w-full"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Reset password
          </Button>
        </Form.Item>
      </Form>
      {hasError && (
        <Alert
          style={{ marginBottom: 25 }}
          message="Error occured"
          type="error"
        />
      )}
      <Button
        className="w-full"
        type="text"
        onClick={() => navigate('/auth/login')}
      >
        Back to login
      </Button>
    </>
  )
}

export default ForgotPasswordPage
