import { Button, Space } from 'antd'
import { SBRMAction, SBRMType } from '../SBRMModel'
import { ReactElement } from 'react'

interface Props {
  isLoading: boolean
  entity: SBRMType | undefined
  action: SBRMAction
  onSave: () => void
  onUpdate: () => void
  onClose: () => void
  style?: React.CSSProperties
}

const DrawerFooter = ({
  isLoading,
  entity,
  action,
  onSave,
  onUpdate,
  onClose,
  style,
}: Props) => {
  return (
    <div style={style}>
      <Space className="p-2 text-right">
        <Button onClick={onClose} disabled={isLoading}>
          Close
        </Button>
        {entity && (action === 'create' || action === 'duplicate') && (
          <Button onClick={onSave} type="primary" loading={isLoading}>
            Save
          </Button>
        )}
        {entity && action === 'update' && (
          <Button
            onClick={onUpdate}
            type="primary"
            color="secondary"
            loading={isLoading}
          >
            Update
          </Button>
        )}
      </Space>
    </div>
  )
}

export default DrawerFooter
