import { AxiosStatic } from 'axios'
import { AuthModel } from '../models/AuthModel'
import { getAuth, setAuth } from './helpers/AuthLocalStorageHelper'

/**
 * Get an access token for Alela API
 */
function getAccessToken() {
  // Check if there is an email connection token stored
  const auth = getAuth()
  if (auth && auth.access_token) {
    return auth.access_token
  }
}

export function setupAxios(axios: AxiosStatic) {
  // Requests default headers
  axios.defaults.headers.common['Accept'] = 'application/json'

  // Request interceptor for API calls
  axios.interceptors.request.use(
    async (config) => {
      const accessToken = getAccessToken()
      if (accessToken) {
        config.headers['Alela-Bearer'] = `Bearer ${accessToken}`
        config.headers['Authorization'] = `Bearer ${accessToken}`
        config.headers.Accept = 'application/json'
      }

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      if (error.response.status === 401 && !originalRequest._retry) {
        /**
         * The API returned a 401 - Unauthorized status code
         */

        // First of all, mark the request as retried
        originalRequest._retry = true

        // Grab a new token
        const access_token = await refreshAccessToken(axios)
        if (!access_token) {
          return Promise.reject(error)
        }

        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${access_token}`
        return axios(originalRequest)
      }
      return Promise.reject(error)
    }
  )
}

/**
 * Refresh the access token
 */
async function refreshAccessToken(axios: AxiosStatic): Promise<string | null> {
  const auth = getAuth()
  const OAUTH_CLIENT_SECRET = process.env.REACT_APP_OAUTH_CLIENT_SECRET
  const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID
  const API_OAUTH_URL = process.env.REACT_APP_API_OAUTH_URL
  const REFRESH_TOKEN_URL = `${API_OAUTH_URL}/token`

  if (!auth?.refresh_token) {
    console.log('No refresh token stored. Abort...')
    return null
  }

  const { data: response } = await axios.post<AuthModel>(REFRESH_TOKEN_URL, {
    grant_type: 'refresh_token',
    client_id: OAUTH_CLIENT_ID,
    client_secret: OAUTH_CLIENT_SECRET,
    refresh_token: auth?.refresh_token,
    scope: '',
  })
  setAuth(response)

  return response.access_token
}

export interface MessageReponse {
  message: string
  details: any
}
export interface APIResponse<T> {
  status: number
  data: T
}
