import { useIntl } from 'react-intl'
import { Form, FormInstance, Input, Select } from 'antd'
import { emailRule, requiredRule, stringRule } from '../../../utils/rules'
import React from 'react'

interface Props {
  form: FormInstance
}

const Create: React.FC<Props> = ({ form }) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        required
        label={'Name'}
        rules={[requiredRule(intl), stringRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="currency"
        required
        label={'Currency'}
        rules={[requiredRule(intl)]}
      >
        <Select
          options={[
            { value: 'eur', label: 'eur' },
            { value: 'usd', label: 'usd' },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="measurementUnit"
        required
        label={'measurementUnit'}
        rules={[requiredRule(intl)]}
      >
        <Select
          options={[
            { value: 'metric', label: 'metric' },
            { value: 'imperial', label: 'imperial' },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="locale"
        required
        label={'locale'}
        rules={[requiredRule(intl)]}
      >
        <Select
          options={[
            { value: 'fr', label: 'fr' },
            { value: 'en', label: 'en' },
          ]}
        />
      </Form.Item>
    </Form>
  )
}

export default Create
