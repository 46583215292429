import { Badge, Space, Typography } from 'antd'

const { Title } = Typography

interface Props {
  title: string
  level?: 1 | 2 | 3 | 4 | 5 | undefined
  toolbar?: React.ReactNode
  badge?: number
}

const PageTitle = ({ title, level = 3, toolbar, badge }: Props) => {
  return (
    <Space
      style={{
        justifyContent: 'space-between',
        marginTop: 20,
        display: 'flex',
        marginBottom: 20,
      }}
      wrap={true}
    >
      <Space direction="horizontal">
        <Title level={level} style={{ margin: 0, wordBreak: 'normal' }}>
          {title}
        </Title>
        <Badge count={badge}></Badge>
      </Space>
      <>{toolbar && toolbar}</>
    </Space>
  )
}

export default PageTitle
