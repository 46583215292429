import {
  UserOutlined,
  BarChartOutlined,
  HomeOutlined,
  ControlOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import RightHeader from './RightHeader'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { extractSectionFromPath } from '../../utils/helpers/RouterHelpers'
import { AlelaPermission } from '../../utils/permissions'
import MenuItem from 'antd/es/menu/MenuItem'
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems'

const { Header } = Layout

interface Props {
  subItems?: ItemType<MenuItemType>[] | undefined
}

const MainHeader = ({ subItems }: Props) => {
  const { pathname } = useLocation()

  const [selectedKey, setSelectedKey] = useState<string>('events')
  const [selectedSubKey, setSelectedSubKey] = useState<string>('contacts')

  const headerStyle: React.CSSProperties = {
    backgroundColor: '#6d7cff',
    justifyContent: 'space-between',
    display: 'flex',
    paddingRight: 20,
    paddingLeft: 20,
  }

  const subHeaderStyle: React.CSSProperties = {
    backgroundColor: '#2C2C2C',
    justifyContent: 'space-between',
    display: 'flex',
    paddingRight: 20,
    paddingLeft: 20,
    height: 50,
    lineHeight: '50px',
  }

  useEffect(() => {
    setSelectedKey(extractSectionFromPath(pathname, 1))
    setSelectedSubKey(extractSectionFromPath(pathname, 2))
  }, [pathname])

  return (
    <div className={'fixed z-20 w-full'}>
      <Header style={headerStyle}>
        <>
          <Link to="/">
            <div className="logo d-flex">
              <img
                src={'/assets/images/logocurve.png'}
                height={45}
                style={{ marginTop: 12 }}
              />
            </div>
          </Link>
          <Menu
            style={{ background: '#6d7cff' }}
            className="desktop-menu d-flex-lg d-none-md d-none-sm d-none-xs"
            mode="horizontal"
            selectedKeys={[selectedKey]}
            items={[
              {
                key: '',
                label: <Link to={'/'}>Home</Link>,
                icon: <HomeOutlined />,
              },
              {
                key: 'tenants',
                label: <Link to={'/tenants'}>Tenants</Link>,
                icon: <UserOutlined />,
              },
              {
                key: 'metrics',
                label: <Link to={'/metrics'}>Metrics</Link>,
                icon: <BarChartOutlined />,
              },
              {
                key: 'controls',
                label: <Link to={'/controls'}>Controls</Link>,
                icon: <ControlOutlined />,
              },
            ]}
          />
        </>
        <div
          style={{
            minWidth: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <RightHeader />
        </div>
      </Header>
      {subItems && (
        <Header style={subHeaderStyle}>
          <div
            style={{
              maxWidth: 1200,
              width: '100%',
              margin: 'auto',
            }}
          >
            <Menu
              style={{ background: '#2C2C2C', border: 'none' }}
              className="d-flex-lg"
              mode="horizontal"
              selectedKeys={[selectedSubKey]}
              items={subItems}
            />
          </div>
        </Header>
      )}
    </div>
  )
}

export default MainHeader
