import { File } from '../../models/File'
import { useAppSelector } from '../../reducers/hooks'
import { Button, Tooltip } from 'antd'
import { selectFileById } from '../../reducers/FileReducer'
import { DownloadOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

interface Props {
  id?: number
  model?: File
}

export const DownloadButton = ({ id, model }: Props) => {
  const file = model ? model : useAppSelector(selectFileById(id ?? 0))

  return file && file.downloadUrl ? (
    <Link to={file.downloadUrl}>
      <Tooltip title={`Download ${file.name}`}>
        <Button icon={<DownloadOutlined />} />
      </Tooltip>
    </Link>
  ) : (
    <></>
  )
}

export type DownloadButtonType = { DownloadButton: typeof DownloadButton }
