import { FormInstance } from 'antd'
import { MetaData, MetaDataKey } from '../../models/MetaData'

export enum SBRMType {
  file = 'file',
  user = 'user',
  role = 'role',
  tenant = 'tenant',
  banner = 'banner',
}

export type SBRMAction = 'create' | 'duplicate' | 'update' | 'view' | 'delete'

export type SBRMReducer = 'file' | 'user' | 'role' | 'tenant' | 'banner'

export interface SBRMFormProps {
  isNested: boolean
}
export interface SBRMFormInterface {
  handleCreate: () => Promise<boolean>
  handleUpdate: () => Promise<boolean>
  handleDelete: () => Promise<boolean>
  handleReset: () => void
}

export interface SBRMFormStepProps {
  form: FormInstance
  isNested: boolean
}

export const getMetadata = (
  metadata: MetaData[] | undefined,
  key: MetaDataKey
) => {
  const extractedData = (metadata ?? []).find((m: MetaData) => m.key === key)
  return extractedData ? extractedData.value : undefined
}

type SBRMLayoutAction = {
  rightPanelVisible: boolean
  rightPanelContent?: 'form'
  leftPanelVisible?: boolean
  leftPanelContent?: 'file-viewer'
}

type SBRMLayout = {
  update: SBRMLayoutAction
  view: SBRMLayoutAction
  create: SBRMLayoutAction
  duplicate: SBRMLayoutAction
  delete?: SBRMLayoutAction
}

export type SBRMTypeInfosnType<T> = Record<
  SBRMType,
  {
    // --- Not used yet START
    creatable: boolean
    updatable: boolean
    viewable: boolean
    deletable: boolean
    duplicable: boolean
    // --- Not used yet END
    layout: SBRMLayout
    filePropertyName?: undefined | null | string // undefined -> no file | null -> resource is file | string -> property to access file
    reducerName: SBRMReducer
  }
>
