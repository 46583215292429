import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Select,
  Space,
  Statistic,
} from 'antd'
import PageTitle from '../../components/PageTitle'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  getTenantWithId,
  selectSelectedTenant,
  setSelectedTenant,
} from '../../reducers/TenantReducer'
import { Tenant } from '../../components/tenant/Tenant'

const TenantPage = () => {
  const navigate = useNavigate()
  const { tenantId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!tenantId) {
      navigate('/tenants')
    }
    dispatch(setSelectedTenant(Number(tenantId)))
    dispatch(getTenantWithId(Number(tenantId)))
  }, [tenantId])

  const tenant = useAppSelector(selectSelectedTenant())

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Space
          style={{
            justifyContent: 'space-between',
            marginTop: 20,
            display: 'flex',
            marginBottom: 20,
          }}
          wrap={true}
        >
          <Space direction="horizontal">
            <Tenant.Avatar model={tenant} />
          </Space>
          <Space>
            <Select
              defaultValue="jack"
              style={{ width: 180 }}
              options={[
                { value: 'jack', label: 'Actif' },
                { value: 'lucy', label: 'Impayé' },
                { value: 'Yiminghe', label: 'Rappel impayé' },
                { value: 'disabled', label: 'Suspendu' },
              ]}
            />
            <Button icon={<EditOutlined />} />
            <Button danger icon={<DeleteOutlined />} />
          </Space>
        </Space>
      </Col>
      <Col span={24}>
        <PageTitle title="Administrative" />
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Card>
              <Descriptions
                title={
                  <Space
                    style={{ justifyContent: 'space-between', width: '100%' }}
                  >
                    Billing infos <Button icon={<EditOutlined />} />
                  </Space>
                }
              >
                <Descriptions.Item label="Company name" span={24}>
                  La tour inferno
                </Descriptions.Item>
                <Descriptions.Item label="Registration number" span={24}>
                  627 383 292
                </Descriptions.Item>
                <Descriptions.Item label="Street" span={20}>
                  1 Rue des choses
                </Descriptions.Item>
                <Descriptions.Item label="Zip" span={12}>
                  75016
                </Descriptions.Item>
                <Descriptions.Item label="City" span={12}>
                  Paris
                </Descriptions.Item>
                <Descriptions.Item label="Country" span={24}>
                  France
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Descriptions
                title={
                  <Space
                    style={{ justifyContent: 'space-between', width: '100%' }}
                  >
                    Plan infos <Button icon={<EditOutlined />} />
                  </Space>
                }
              >
                <Descriptions.Item label="Plan" span={24}>
                  Professional
                </Descriptions.Item>
                <Descriptions.Item label="Type" span={24}>
                  Monthly
                </Descriptions.Item>
                <Descriptions.Item label="Plan bithdate" span={24}>
                  23-03-2022
                </Descriptions.Item>
                <Descriptions.Item label="Users" span={24}>
                  28
                </Descriptions.Item>
                <Descriptions.Item label="Price per user/month" span={24}>
                  10€
                </Descriptions.Item>
                <Descriptions.Item label="Total per month" span={4}>
                  280€
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Descriptions
                title={
                  <Space
                    style={{ justifyContent: 'space-between', width: '100%' }}
                  >
                    Prospect infos <Button icon={<EditOutlined />} />
                  </Space>
                }
              >
                <Descriptions.Item label="First name" span={24}>
                  Jaques
                </Descriptions.Item>
                <Descriptions.Item label="Last name" span={24}>
                  Mouloude
                </Descriptions.Item>
                <Descriptions.Item label="Phone" span={24}>
                  +337839393
                </Descriptions.Item>
                <Descriptions.Item label="Email" span={24}>
                  23-03-2022
                </Descriptions.Item>
                <Descriptions.Item label="Job" span={24}>
                  Président en présidence
                </Descriptions.Item>
                <Descriptions.Item label="Notes" span={24}>
                  Super sympa
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <PageTitle title="Settings" />
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Card>
              <Descriptions
                title={
                  <Space
                    style={{ justifyContent: 'space-between', width: '100%' }}
                  >
                    Intern infos <Button icon={<EditOutlined />} />
                  </Space>
                }
              >
                <Descriptions.Item label="Tenant id" span={24}>
                  xzde-defrv-vrtevbet-gvrdfs-vre-fb
                </Descriptions.Item>
                <Descriptions.Item label="Created at" span={24}>
                  20-12-2022
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Descriptions
                title={
                  <Space
                    style={{ justifyContent: 'space-between', width: '100%' }}
                  >
                    OCR infos <Button icon={<EditOutlined />} />
                  </Space>
                }
              ></Descriptions>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Descriptions
                title={
                  <Space
                    style={{ justifyContent: 'space-between', width: '100%' }}
                  >
                    Storage infos <Button icon={<EditOutlined />} />
                  </Space>
                }
              >
                <Descriptions.Item label="Bucket id" span={24}>
                  SYZGHDIO
                </Descriptions.Item>
                <Descriptions.Item label="Bucket info" span={24}>
                  Mouloude
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <PageTitle title="Metrics" />
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Card>
              <Statistic title="Mindee this month" value={2390} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title="Stockage this month"
                value={112893}
                suffix={'Go'}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Mailgun request this month" value={125} />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Card>
              <Descriptions title="Usage metrics">
                <Descriptions.Item label="Events" span={24}>
                  2672
                </Descriptions.Item>
                <Descriptions.Item label="Artists" span={24}>
                  15267
                </Descriptions.Item>
                <Descriptions.Item label="Contacts" span={24}>
                  2562
                </Descriptions.Item>
                <Descriptions.Item label="Invoices" span={24}>
                  262
                </Descriptions.Item>
                <Descriptions.Item label="Contract" span={24}>
                  1888
                </Descriptions.Item>
                <Descriptions.Item label="Rider" span={24}>
                  1817
                </Descriptions.Item>
                <Descriptions.Item label="Performances" span={24}>
                  2627
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={16}>
            <Card title={'Activities this month'}>
              Graph de histo du count activities journalier
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default TenantPage
