import { useIntl } from 'react-intl'
import { Form, FormInstance, Input, Select } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { File } from '../../../components/file/File'
import TextArea from 'antd/es/input/TextArea'
import { SBAsyncSelect } from '../../../components/custom-inputs/select/SBAsyncSelect'
import { SBRMType } from '../SBRMModel'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <SBAsyncSelect name="tenant" label="Tenant" type={SBRMType.tenant} />
      <Form.Item
        name="type"
        label="Type"
        rules={[requiredRule(intl)]}
        initialValue="error"
      >
        <Select
          options={[
            { value: 'success', label: 'Success' },
            { value: 'info', label: 'Info' },
            { value: 'warning', label: 'Warning' },
            { value: 'error', label: 'Error' },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="message"
        label="Message - can use HTML"
        rules={[requiredRule(intl)]}
      >
        <TextArea showCount maxLength={255} />
      </Form.Item>
    </Form>
  )
}

export default Create
