import {
  CalendarOutlined,
  UserOutlined,
  BarChartOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { extractSectionFromPath } from '../../utils/helpers/RouterHelpers'
import { AlelaPermission } from '../../utils/permissions'
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems'

const { Header } = Layout

interface Props {
  subItems?: ItemType<MenuItemType>[] | undefined
}

const MainHeaderMobile = ({ subItems }: Props) => {
  const { pathname } = useLocation()
  const [selectedKey, setSelectedKey] = useState<string>('events')

  const headerStyle: React.CSSProperties = {
    backgroundColor: '#1E1E1E',
    bottom: 0,
  }

  useEffect(() => {
    setSelectedKey(extractSectionFromPath(pathname, 1))
  }, [pathname])

  return (
    <Header className={'fixed z-20 w-full d-none-lg'} style={headerStyle}>
      <Menu
        className="mobile-menu"
        mode="horizontal"
        selectedKeys={[selectedKey]}
        items={[
          {
            key: 'events',
            label: (
              <Link to={'/events'}>
                <CalendarOutlined />
              </Link>
            ),
          },
          {
            key: 'artists',
            label: (
              <Link to={'/artists'}>
                <UserOutlined />
              </Link>
            ),
          },
          {
            key: 'financials',
            label: (
              <Link to={'/financials'}>
                <BarChartOutlined />
              </Link>
            ),
          },
          {
            key: 'contacts',
            label: (
              <Link to={'/crm/contacts'}>
                <TeamOutlined />
              </Link>
            ),
          },
        ]}
      />
    </Header>
  )
}

export default MainHeaderMobile
