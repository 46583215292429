import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import { emailRule, requiredRule } from '../../../utils/rules'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="firstName"
        required
        label={'First name'}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="lastName"
        required
        label={'Last name'}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        required
        label={'Email'}
        rules={[requiredRule(intl), emailRule(intl)]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default Create
