import { Badge, Card, Col, Row, Skeleton, Statistic } from 'antd'
import PageTitle from '../components/PageTitle'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { HOME_URL } from '../utils/urls'

const HomePage = () => {
  const [homeData, setHomeData] = useState<
    { tenants: number; users: number; tenantUserRatio: number } | undefined
  >(undefined)
  useEffect(() => {
    axios.get(HOME_URL).then((response) => setHomeData(response.data.data))
  }, [])

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <PageTitle title="Overview" />
        {homeData === undefined && <Skeleton />}
        {homeData !== undefined && (
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Card>
                <Statistic title="Tenants" value={homeData.tenants} />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic title="Users" value={homeData.users} />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title="User/tenant"
                  value={homeData.tenantUserRatio}
                />
              </Card>
            </Col>
          </Row>
        )}
      </Col>
      <Col span={24}>
        <PageTitle title="Services" />
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Badge.Ribbon text="Online" color="green">
              <Card>
                <Statistic title="Production front-end" value={'XXXX'} />
              </Card>
            </Badge.Ribbon>
          </Col>
          <Col span={6}>
            <Badge.Ribbon text="Online" color="green">
              <Card>
                <Statistic title="Production backend" value={'XXXX'} />
              </Card>
            </Badge.Ribbon>
          </Col>
          <Col span={6}>
            <Badge.Ribbon text="Offline" color="red">
              <Card>
                <Statistic title="Staging front-end" value={'XXXX'} />
              </Card>
            </Badge.Ribbon>
          </Col>
          <Col span={6}>
            <Badge.Ribbon text="Offline" color="red">
              <Card>
                <Statistic title="Staging backend" value={'XXXX'} />
              </Card>
            </Badge.Ribbon>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default HomePage
