import { schema } from 'normalizr'

const userSchema = new schema.Entity('user')
const bookingSchema = new schema.Entity('booking')
const bookingTypeSchema = new schema.Entity('bookingType')
const artistSchema = new schema.Entity('artist')
const eventSchema = new schema.Entity('event')
const eventStatusSchema = new schema.Entity('eventStatus')
const venueSchema = new schema.Entity('venue')
const venueTypeSchema = new schema.Entity('venueType')
const stageSchema = new schema.Entity('stage')
const hotelSchema = new schema.Entity('hotel')
const roomSchema = new schema.Entity('room')
const invoiceSchema = new schema.Entity('invoice')
const paymentSchema = new schema.Entity('payment')
const riderSchema = new schema.Entity('rider')
const contractSchema = new schema.Entity('contract')
const performanceSchema = new schema.Entity('performance')
const contactSchema = new schema.Entity('contact')
const contactTypeSchema = new schema.Entity('contactType')
const contactAssociationSchema = new schema.Entity('contactAssociation')
const fileSchema = new schema.Entity('file')
const fileTypeSchema = new schema.Entity('fileType')
const travelSchema = new schema.Entity('travel')
const travelTypeSchema = new schema.Entity('travelType')
const groundSchema = new schema.Entity('ground')
const stationSchema = new schema.Entity('station')
const stationTypeSchema = new schema.Entity('stationType')
const publicLinkSchema = new schema.Entity('publicLink')
const roleSchema = new schema.Entity('role')
const permissionSchema = new schema.Entity('permission')
const addressSchema = new schema.Entity('address')
const invoiceCategorySchema = new schema.Entity('invoiceCategory')
const supplierSchema = new schema.Entity('supplier')
const supplierTypeSchema = new schema.Entity('supplierType')
const tenantSchema = new schema.Entity('tenant')
const bannerSchema = new schema.Entity('banner')

userSchema.define({
  contact: contactSchema,
})

bookingSchema.define({
  artist: artistSchema,
  event: eventSchema,
  rooms: [roomSchema],
  invoices: [invoiceSchema],
  riders: [riderSchema],
  performances: [performanceSchema],
  owner: userSchema,
  type: bookingTypeSchema,
  contacts: [contactSchema],
  association: contactTypeSchema,
})

artistSchema.define({
  bookings: [bookingSchema],
  contacts: [contactSchema],
})

eventSchema.define({
  bookings: [bookingSchema],
  stages: [stageSchema],
  venues: [venueSchema],
  invoices: [invoiceSchema],
  performances: [performanceSchema],
  status: eventStatusSchema,
  contacts: [contactSchema],
  files: [fileSchema],
})

eventStatusSchema.define({
  visibleByRoles: [roleSchema],
})

venueSchema.define({
  type: venueTypeSchema,
  stages: [stageSchema],
  contacts: [contactSchema],
})

venueTypeSchema.define({
  venues: [venueSchema],
})

stageSchema.define({
  venue: venueSchema,
  performances: [performanceSchema],
})

hotelSchema.define({
  rooms: [roomSchema],
})

roomSchema.define({
  hotel: hotelSchema,
  booking: bookingSchema,
  contacts: [contactSchema],
})

invoiceSchema.define({
  booking: bookingSchema,
  event: eventSchema,
  files: [fileSchema],
  category: invoiceCategorySchema,
  payments: [paymentSchema],
  supplier: supplierSchema,
})

paymentSchema.define({
  invoice: invoiceSchema,
  pop: fileSchema,
})

supplierSchema.define({
  invoices: [invoiceSchema],
  type: supplierTypeSchema,
  contacts: [contactSchema],
})

riderSchema.define({
  booking: bookingSchema,
})

contractSchema.define({
  booking: bookingSchema,
})

performanceSchema.define({
  booking: bookingSchema,
  stage: stageSchema,
})

contactSchema.define({
  associations: [contactAssociationSchema],
  bookings: [bookingSchema],
  artists: [artistSchema],
  venues: [venueSchema],
  events: [eventSchema],
})

fileSchema.define({
  type: fileTypeSchema,
  event: eventSchema,
  booking: bookingSchema,
  publicLinks: [publicLinkSchema],
})

travelSchema.define({
  departure: stationSchema,
  arrival: stationSchema,
  type: travelTypeSchema,
  booking: bookingSchema,
  contacts: [contactSchema],
})

groundSchema.define({
  booking: bookingSchema,
  from: {
    venue: venueSchema,
    hotel: hotelSchema,
    travel: travelSchema,
    customaddress: { address: addressSchema },
  },
  to: {
    venue: venueSchema,
    hotel: hotelSchema,
    travel: travelSchema,
    customaddress: { address: addressSchema },
  },
  driver: contactSchema,
  passengers: [contactSchema],
})

stationSchema.define({
  type: stationTypeSchema,
})

permissionSchema.define({
  roles: [roleSchema],
})

publicLinkSchema.define({
  //
})

tenantSchema.define({
  //
})

bannerSchema.define({
  tenant: tenantSchema,
})

export {
  userSchema,
  bookingSchema,
  bookingTypeSchema,
  artistSchema,
  eventSchema,
  eventStatusSchema,
  venueSchema,
  venueTypeSchema,
  stageSchema,
  hotelSchema,
  roomSchema,
  invoiceSchema,
  paymentSchema,
  riderSchema,
  contractSchema,
  performanceSchema,
  contactSchema,
  contactTypeSchema,
  contactAssociationSchema,
  fileSchema,
  fileTypeSchema,
  travelSchema,
  travelTypeSchema,
  groundSchema,
  stationSchema,
  stationTypeSchema,
  publicLinkSchema,
  roleSchema,
  permissionSchema,
  addressSchema,
  invoiceCategorySchema,
  supplierSchema,
  supplierTypeSchema,
  tenantSchema,
  bannerSchema,
}
