import { FC, useLayoutEffect } from 'react'
import App from '../App'
import { AuthLayout } from '../layouts/AuthLayout'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { LayoutGeneral } from '../layouts/LayoutGeneral'
import LoginPage from '../pages/auth/LoginPage'

import ResetPasswordPage from '../pages/auth/ResetPasswordPage'
import ForgotPasswordPage from '../pages/auth/ForgotPasswordPage'

import SettingsRoutes from './settings/SettingsRoutes'

import ProfileRoutes from './ProfileRoutes'
import LayoutLogged from '../layouts/LayoutLogged'

import HomePage from '../pages/HomePage'
import TenantRoutes from './tenants/TenantRoutes'
import MetricsPage from '../pages/MetricsPage'
import ControlsPage from '../pages/ControlsPage'

const AppRoutes: FC = () => {
  const location = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<App />}>
        <Route element={<LayoutLogged />}>
          <Route element={<LayoutGeneral />}>
            <Route index element={<HomePage />} />
            <Route path="/tenants/*" element={<TenantRoutes />} />
            <Route path="/metrics" element={<MetricsPage />} />
            <Route path="/controls" element={<ControlsPage />} />
          </Route>
          <Route path="/settings/*" element={<SettingsRoutes />} />
          <Route path="/account/*" element={<ProfileRoutes />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />

        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route index path="*" element={<Navigate to="login" />} />
        </Route>
      </Route>
    </Routes>
  )
}

export { AppRoutes }
