const BASE_API_URL = process.env.REACT_APP_API_URL
const API_URL = BASE_API_URL + '/admin/v1'
const ACCOUNT_URL = API_URL + '/account'

const OAUTH_API_URL = process.env.REACT_APP_API_OAUTH_URL
export const LOGIN_URL = `${OAUTH_API_URL}/token`
export const LOGOUT_URL = `${API_URL}/auth/logout`
export const REGISTER_URL = `${API_URL}/auth/register`
export const GET_LOGGED_USER_INFOS = `${API_URL}/auth/me`
export const USER_PICTURE_URL = `${API_URL}/auth/update-picture`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const UPDATE_ORGANIZATION_LOGO = `${API_URL}/auth/tenant/logo`

export const PING_URL = API_URL + '/ping'
export const TENANT_URL = API_URL + '/tenants'
export const BANNER_URL = API_URL + '/banners'
export const SETTING_URL = API_URL + '/settings'
export const HOME_URL = API_URL + '/home'

export const CUSTOM_ADDRESS_URL = API_URL + '/custom-addresses'
export const ROADSHEETS_URL = API_URL + '/roadsheets'
export const EVENTS_URL = API_URL + '/events'
export const EVENT_STATUSES_URL = API_URL + '/event-statuses'
export const HOTELS_URL = API_URL + '/hotels'
export const ROOMS_URL = API_URL + '/rooms'
export const BOOKINGS_URL = API_URL + '/bookings'
export const BOOKING_TYPES_URL = API_URL + '/booking-types'
export const FILES_URL = API_URL + '/files'
export const PUBLIC_LINKS_URL = API_URL + '/public-links'
export const RIDERS_URL = API_URL + '/riders'
export const INVOICES_URL = API_URL + '/invoices'
export const INVOICE_CATEGORIES_URL = API_URL + '/invoice-categories'
export const PAYMENTS_URL = API_URL + '/payments'
export const SUPPLIERS_URL = API_URL + '/suppliers'
export const SUPPLIER_TYPES_URL = API_URL + '/supplier-types'
export const ARTISTS_URL = API_URL + '/artists'
export const STATIONS_URL = API_URL + '/stations'
export const STATION_TYPES_URL = API_URL + '/station-types'
export const CONTRACTS_URL = API_URL + '/contracts'
export const VENUES_URL = API_URL + '/venues'
export const VENUE_TYPES_URL = API_URL + '/venue-types'
export const CONTACTS_URL = API_URL + '/contacts'
export const CONTACT_ASSOCIATION_URL = API_URL + '/contact-associations'
export const CONTACTS_MISSING_PARTY_CREW_URL =
  API_URL + '/contacts/missing-in-party-crew'
export const CONTACT_TYPES_URL = API_URL + '/contact-types'
export const STAGES_URL = API_URL + '/stages'
export const TRAVELS_URL = API_URL + '/travels'
export const TRAVEL_TYPE_URL = API_URL + '/travel-types'
export const PERFORMANCES_URL = API_URL + '/performances'
export const GROUND_URL = API_URL + '/ground'
export const KANBAN_LANES_URL = API_URL + '/kanban/lanes'
export const KANBAN_CARDS_URL = API_URL + '/kanban/cards'
export const KANBAN_TAGS_URL = API_URL + '/kanban/tags'
export const USERS_URL = API_URL + '/users'

export const ACCOUNT_USERS_URL = ACCOUNT_URL + '/users'
export const ROLES_URL = ACCOUNT_URL + '/roles'
export const USER_ROLE_URL = ACCOUNT_URL + '/profile'
