import { Card, Space } from 'antd'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAuth } from '../../utils/auth/Auth'
import PageTitle from '../../components/PageTitle'
import { useAppDispatch } from '../../reducers/hooks'

const PreferencesPage = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { currentAdmin } = useAuth()

  useEffect(() => {
    //
  }, [dispatch])

  return (
    <>
      <PageTitle title="Preferences" />
      <Card>
        <Space direction="vertical"></Space>
      </Card>
    </>
  )
}

export default PreferencesPage
