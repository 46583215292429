import { Alert, Spin } from 'antd'
import { File } from '../../models/File'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { pdfjs, Document, Page } from 'react-pdf'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useAppSelector } from '../../reducers/hooks'
import { Options } from 'react-pdf/dist/cjs/shared/types'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

interface Props {
  file: File
  displayThumbnails?: boolean
  handleSuccessLoaded: () => void
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const options: Options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
}

const PDFViewer = ({
  file,
  displayThumbnails = false,
  handleSuccessLoaded,
}: Props) => {
  const [numPages, setNumPages] = useState<number>()
  const [width, setWidth] = useState<number>(500)

  const componentRef = useRef<HTMLHeadingElement>(null)

  const { isOpen: SBRMisOpen } = useAppSelector((state) => state.SBRM)

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages)
    handleSuccessLoaded()
  }

  /**
   * Maximum width to display PDF is 880
   * In case the container is wider than that, stick the value to 880
   */
  const pageWidth = width > 880 ? 800 : width - 80

  useEffect(() => {
    if (!SBRMisOpen || !componentRef.current) {
      return
    }
    setWidth(componentRef.current?.offsetWidth ?? 0)
  }, [SBRMisOpen, componentRef.current])

  useLayoutEffect(() => {
    const handleResize = () => {
      setWidth(componentRef.current?.offsetWidth ?? 0)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div ref={componentRef}>
      <Document
        file={file.url}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
        loading={
          <Spin
            style={{
              top: 'calc(50vh - 57px)',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          />
        }
        error={
          <Alert
            style={{
              top: '50%',
              left: '50%',
              position: 'absolute',
              transform: 'translate(-50%,-50%)',
            }}
            type="error"
            message="Error with your file when we try to load it"
          />
        }
      >
        <div
          style={{
            background: '#252425',
            flex: 1,
            overflow: 'scroll',
          }}
        >
          <div style={{ margin: '24px', overflow: 'initial' }}>
            <div
              style={{
                width: pageWidth,
                margin: 'auto',
              }}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <div key={`page_${index + 1}`} style={{ paddingBottom: 10 }}>
                  <Page
                    canvasBackground="#fff"
                    renderAnnotationLayer={false}
                    renderTextLayer={true}
                    width={pageWidth}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Document>
    </div>
  )
}

export default PDFViewer
