import {
  CalendarOutlined,
  StarOutlined,
  ToolOutlined,
  HomeOutlined,
  TeamOutlined,
  BankOutlined,
  PictureOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../utils/auth/Auth'
import { Avatar, Card, Divider, Menu, Space, Typography } from 'antd'
import { extractSectionFromPath } from '../../utils/helpers/RouterHelpers'
import { File } from '../../components/file/File'
import { UPDATE_ORGANIZATION_LOGO } from '../../utils/urls'

const { Title } = Typography

const SettingsSider = () => {
  const { pathname } = useLocation()
  const { currentTenant, saveTenant } = useAuth()
  const [selectedKey, setSelectedKey] = useState<string>('venues')
  const [logoFormIsVisible, setLogoFormIsVisible] = useState<boolean>(false)

  useEffect(() => {
    const menuItemSelected = extractSectionFromPath(pathname, 2)
    setSelectedKey(menuItemSelected)
  }, [pathname])

  return (
    <Card
      bodyStyle={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}
      bordered={false}
    >
      <Space direction="vertical" className="w-full">
        <Space direction="vertical" className="text-center w-full">
          {!logoFormIsVisible && (
            <Avatar
              size={50}
              src={currentTenant?.logo}
              onClick={() => setLogoFormIsVisible(true)}
            />
          )}
          {logoFormIsVisible && (
            <File.Upload
              icon={<PictureOutlined />}
              title="Upload Tenant image"
              action={UPDATE_ORGANIZATION_LOGO}
              accept={'image/*'}
              onSuccessUpload={(result) => {
                saveTenant(result.data.data)
                setLogoFormIsVisible(false)
              }}
            />
          )}
          <Space direction="vertical" size={0}>
            <Title level={4} style={{ margin: 0 }}>
              {currentTenant?.name}
            </Title>
          </Space>
        </Space>
        <Divider style={{ margin: '5px 0 5px 0' }} />
        <Menu
          mode="vertical"
          selectedKeys={[selectedKey]}
          style={{ border: 0 }}
          items={[
            {
              key: 'venues',
              label: <Link to={'venues'}>Venues</Link>,
              icon: <HomeOutlined />,
            },
            {
              key: 'venue-types',
              label: <Link to={'venue-types'}>Venue types</Link>,
              icon: <ToolOutlined />,
            },
            {
              key: 'event-statuses',
              label: <Link to={'event-statuses'}>Event statuses</Link>,
              icon: <CalendarOutlined />,
            },
            {
              key: 'hotels',
              label: <Link to={'hotels'}>Hotels</Link>,
              icon: <StarOutlined />,
            },
            {
              key: 'contact-types',
              label: <Link to={'contact-types'}>Contact types</Link>,
              icon: <ToolOutlined />,
            },
            {
              key: 'stations',
              label: <Link to={'stations'}>Stations</Link>,
              icon: <ToolOutlined />,
            },
            {
              type: 'divider',
              key: 'divider-2',
              dashed: false,
            },
            {
              key: 'tenant',
              label: <Link to={'tenant'}>Tenant</Link>,
              icon: <BankOutlined />,
            },
          ]}
        />
      </Space>
    </Card>
  )
}

export default SettingsSider
