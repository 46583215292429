import { Alert, Button, Card, Form, Input, Space, Typography } from 'antd'
import { useIntl } from 'react-intl'
import { useAuth } from '../../utils/auth/Auth'
import PageTitle from '../../components/PageTitle'
import { useEffect, useState } from 'react'
import {
  requiredRule,
  stringRule,
  validPhoneNumberRule,
} from '../../utils/rules'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const ProfilePage = () => {
  const intl = useIntl()
  const [form] = Form.useForm()

  const { currentAdmin, updateAdmin } = useAuth()

  const [feedback, setFeedback] = useState<
    { type: 'success' | 'error'; message: string; visible: boolean } | undefined
  >()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    form.setFieldsValue(currentAdmin)
  }, [currentAdmin])

  return (
    <>
      <PageTitle title="Informations" />
      <Card bordered={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={() => {
            setIsLoading(true)
            updateAdmin(form.getFieldsValue())
              .then((_) => {
                setIsLoading(false)
                setFeedback({
                  type: 'success',
                  message: 'User updated!',
                  visible: true,
                })
              })
              .catch((_) => {
                setIsLoading(false)
                setFeedback({
                  type: 'error',
                  message: 'Cannot update...',
                  visible: true,
                })
              })
          }}
        >
          <Form.Item
            name="name"
            label={'Name'}
            rules={[requiredRule(intl), stringRule(intl)]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label={
              <>
                Email -{' '}
                <Typography.Text italic>
                  The email cannot be changed
                </Typography.Text>
              </>
            }
          >
            <Input disabled />
          </Form.Item>

          <Space direction="horizontal">
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Update
            </Button>
            {feedback && feedback.visible && (
              <Alert
                type={feedback.type}
                message={feedback.message}
                closable
                afterClose={() => setFeedback({ ...feedback, visible: false })}
              />
            )}
          </Space>
        </Form>
      </Card>
    </>
  )
}

export default ProfilePage
