import './SBRM.css'
import { useState } from 'react'
import NestedSBRM from './NestedSBRM'
import { useSBRMforms } from './hooks'
import { getSBRMTitle } from './SBRMTitle'
import { SBRMTypeInfos } from './SBRMTypeInfos'
import { close } from '../../reducers/SBRMReducer'
import FileViewer from '../file-viewer/FileViewer'
import DrawerFooter from './components/DrawerFooter'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import { Button, Drawer, message, Modal, Space, Typography } from 'antd'

const { Text } = Typography

const SBRM = () => {
  const dispatch = useAppDispatch()
  const [messageApi, contextHolder] = message.useMessage()
  const { refs: SBRMRefs, forms: SBRMForms } = useSBRMforms({ isNested: false })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { isOpen, entity, action } = useAppSelector((state) => state.SBRM)

  const onSave = () => {
    setIsLoading(true)
    SBRMRefs[entity!].current
      ?.handleCreate()
      .then(fireThatSuccess)
      .catch(fireThatError)
  }

  const onUpdate = () => {
    setIsLoading(true)
    SBRMRefs[entity!].current
      ?.handleUpdate()
      .then(fireThatSuccess)
      .catch(fireThatError)
  }

  const onDelete = () => {
    setIsLoading(true)
    SBRMRefs[entity!].current
      ?.handleDelete()
      .then(fireThatSuccess)
      .catch(fireThatError)
  }

  const onClose = () => {
    SBRMRefs[entity!].current?.handleReset()
    dispatch(close())
  }

  const fireThatSuccess = (result: any) => {
    messageApi.open({
      type: 'success',
      content: 'Alelaaaaa',
    })
    setIsLoading(false)
    onClose()
  }

  const fireThatError = (e: any) => {
    messageApi.open({
      type: 'error',
      content: 'Oops ...',
    })
    setIsLoading(false)
  }

  const displayLeftPanel = (): boolean => {
    if (!entity) {
      return false
    }

    return SBRMTypeInfos[entity!].layout[action]?.leftPanelVisible ?? false
  }
  const displayRightPanel = (): boolean => {
    if (!entity) {
      return false
    }

    return SBRMTypeInfos[entity!].layout[action]?.rightPanelVisible ?? false
  }

  return (
    <>
      {/* Context to display messages */}
      {contextHolder}

      {/* Drawer is all action except delete - i.e. create, update & view */}
      {action !== 'delete' && (
        <Drawer
          open={isOpen}
          onClose={onClose}
          title={getSBRMTitle(entity, action)}
          // --- Style
          bodyStyle={{ padding: 0 }}
          height={'100%'}
          width={displayLeftPanel() ? '100%' : 600} // si on doit aficher un viewer alors il nous faut de la place
          placement={displayRightPanel() ? 'right' : 'bottom'} // si pas de formulaire alors on vient d'en bas
        >
          <div className="SBRMDrawerWrapper">
            {!entity && (
              <Text>Choose what to do first and come back later</Text>
            )}

            {/* Left Panel - i.e. File Viewer */}
            {displayLeftPanel() &&
              SBRMTypeInfos[entity!].layout[action]?.leftPanelContent ===
                'file-viewer' && (
                <div className="SBRMFileViewerWrapper">
                  <FileViewer entity={entity!} />
                </div>
              )}

            {/* Right Panel - i.e. Form & Footer */}
            {displayRightPanel() && (
              <div className="SBRMRightPanelWrapper">
                {SBRMTypeInfos[entity!].layout[action]?.rightPanelContent ===
                  'form' && (
                  <>
                    <div className="SBRMFormWrapper">
                      {entity && SBRMForms[entity]}
                    </div>
                    <div className="SBRMFooterWrapper">
                      <DrawerFooter
                        isLoading={isLoading}
                        entity={entity}
                        action={action}
                        onSave={onSave}
                        onUpdate={onUpdate}
                        onClose={onClose}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          {/* 
            Nested SBRM
            It manages itself Drawer/delete modal
           */}
          <NestedSBRM messageApi={messageApi} />
        </Drawer>
      )}

      {/* Modal for delete */}
      {action === 'delete' && (
        <Modal
          open={isOpen}
          onCancel={onClose}
          footer={
            <Space className="p-2 text-right">
              <Button onClick={onClose} disabled={isLoading}>
                Close
              </Button>
              {entity && (
                <Button
                  onClick={onDelete}
                  type="primary"
                  color="danger"
                  loading={isLoading}
                >
                  Delete
                </Button>
              )}
            </Space>
          }
        >
          {!entity && <Text>Choose what to do first and come back later</Text>}
          {entity && SBRMForms[entity]}
        </Modal>
      )}
    </>
  )
}

export default SBRM
