import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import React from 'react'
import { ButtonType } from 'antd/es/button'
import { MetaData } from '../../../models/MetaData'
import { SBRMAction, SBRMType } from '../SBRMModel'
import { Button, Dropdown, Space, MenuProps } from 'antd'
import { useAppDispatch } from '../../../reducers/hooks'

import { setEntity } from '../../../reducers/SBRMReducer'
import { setSelectedAdmin } from '../../../reducers/UserReducer'
import { setSelectedFile } from '../../../reducers/FileReducer'
import { setSelectedRole } from '../../../reducers/RoleReducer'
import { setSelectedTenant } from '../../../reducers/TenantReducer'
import { setSelectedBanner } from '../../../reducers/BannerReducer'

interface Props {
  actions: SBRMAction[]
  entity: SBRMType
  entityId?: number
  metadata?: MetaData[]
  style?: 'buttons-horizontal' | 'dropdown'
  trigger?: React.ReactNode
  bypassHasAccessCheck?: boolean
}

export const Actions = ({
  actions,
  entity,
  entityId,
  metadata,
  style = 'buttons-horizontal',
  trigger,
  bypassHasAccessCheck = false,
}: Props) => {
  const dispatch = useAppDispatch()

  const methods = {
    [SBRMType.file]: setSelectedFile,
    [SBRMType.user]: setSelectedAdmin,
    [SBRMType.role]: setSelectedRole,
    [SBRMType.tenant]: setSelectedTenant,
    [SBRMType.banner]: setSelectedBanner,
  }

  const handleSBRMAction = (action: SBRMAction) => {
    if (action !== 'create') {
      /**
       * This will set the selected id in the resource reducer
       * Using `as any` to silent TS errors
       */
      dispatch((methods[entity] as any)(entityId!) as any)
    }

    // This will set the entity and open the QuickAdd drawer
    dispatch(
      setEntity({
        entity: entity,
        action: action,
        metadata: metadata,
      })
    )
  }

  // If trigger is defined we render it
  // Only the first actions - actions[0] - is considered when trigger is defined
  if (trigger) {
    // Ensure user has the right permission

    return (
      <div
        onClick={() => handleSBRMAction(actions[0])}
        style={{ cursor: 'pointer' }}
      >
        {trigger}
      </div>
    )
  }

  // TODO: Type that properly
  const buttonsArrayFilters = (array: any) =>
    array.filter((item: any) => actions.includes(item.key))

  const items: MenuProps['items'] = buttonsArrayFilters([
    {
      key: 'create' as SBRMAction,
      label: 'Add new',
      onClick: () => handleSBRMAction('create'),
    },
    {
      key: 'update' as SBRMAction,
      label: 'Update',
      onClick: () => handleSBRMAction('update'),
    },
    {
      key: 'delete' as SBRMAction,
      label: 'Delete',
      onClick: () => handleSBRMAction('delete'),
    },
    {
      key: 'duplicate' as SBRMAction,
      label: 'Duplicate',
      onClick: () => handleSBRMAction('duplicate'),
    },
  ])

  const horizontalButtons: {
    key: SBRMAction
    type?: ButtonType
    danger?: boolean
    icon?: React.ReactNode
    label?: React.ReactNode
    onClick: () => void
  }[] = buttonsArrayFilters([
    {
      key: 'create' as SBRMAction,
      type: 'primary' as ButtonType,
      label: 'Add new',
      onClick: () => handleSBRMAction('create'),
    },
    {
      key: 'view' as SBRMAction,
      icon: <EyeOutlined />,
      onClick: () => handleSBRMAction('view'),
    },
    {
      key: 'update' as SBRMAction,
      icon: <EditOutlined />,
      onClick: () => handleSBRMAction('update'),
    },
    {
      key: 'delete' as SBRMAction,
      danger: true,
      icon: <DeleteOutlined />,
      onClick: () => handleSBRMAction('delete'),
    },
    {
      key: 'duplicate' as SBRMAction,
      icon: <CopyOutlined />,
      onClick: () => handleSBRMAction('duplicate'),
    },
  ])

  return (
    <>
      {style === 'buttons-horizontal' && (
        <Space direction="horizontal">
          {horizontalButtons.map((button) => (
            <Button
              key={button.key}
              type={button.type}
              danger={button.danger}
              icon={button.icon}
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          ))}
        </Space>
      )}
      {style === 'dropdown' && (
        <Dropdown menu={{ items }} placement="bottomRight">
          <Button icon={<EllipsisOutlined />} type={'text'} />
        </Dropdown>
      )}
    </>
  )
}
