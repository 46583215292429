import './App.css'
import axios from 'axios'
import SBRM from './modules/sbrm/SBRM'
import { Outlet } from 'react-router-dom'
import { AuthInit } from './utils/auth/Auth'
import { setupAxios } from './utils/setupAxios'

function App() {
  /**
   * Inject interceptors for axios.
   *
   * @see https://github.com/axios/axios#interceptors
   */
  setupAxios(axios)

  return (
    <AuthInit>
      <Outlet />
      <SBRM />
    </AuthInit>
  )
}

export default App
