import { configureStore } from '@reduxjs/toolkit'
import SBRMReducer from './SBRMReducer'
import UserReducer from './UserReducer'
import FileReducer from './FileReducer'
import FileTypeReducer from './FileTypeReducer'
import PermissionReducer from './PermissionReducer'
import AddressReducer from './AddressReducer'
import RoleReducer from './RoleReducer'
import TenantReducer from './TenantReducer'
import BannerReducer from './BannerReducer'

export const store = configureStore({
  reducer: {
    SBRM: SBRMReducer,
    user: UserReducer,
    file: FileReducer,
    role: RoleReducer,
    fileType: FileTypeReducer,
    permission: PermissionReducer,
    address: AddressReducer,
    tenant: TenantReducer,
    banner: BannerReducer,
  },
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
