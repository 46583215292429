import { Button, Card, Space, Statistic, Tag, Typography } from 'antd'
import PageTitle from '../../components/PageTitle'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../reducers/hooks'
import {
  getTenants,
  selectTenants,
  setTenantQuery,
} from '../../reducers/TenantReducer'
import { initialQuery } from '../../utils/helpers/crud/models'
import { extractSortDataFromSorterResult } from '../../utils/table/sorter'
import { TableParams } from '../../models/TableParams'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Tenant as TenantType } from '../../models/Tenant'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import SBTable from '../../components/SBTable'
import { Tenant } from '../../components/tenant/Tenant'
import { DatabaseOutlined, FileOutlined, UserOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

const TenantsPage = () => {
  const dispatch = useAppDispatch()

  const items = useAppSelector(selectTenants())
  const { query, isLoading } = useAppSelector((state) => state.tenant)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const columns: ColumnsType<TenantType> = [
    {
      key: 'name',
      title: 'Name',
      render: (record: TenantType) => <Tenant.Avatar model={record} />,
    },
    {
      key: 'users',
      title: 'Users',
      align: 'center',
      render: (record: TenantType) => (
        <Tag icon={<UserOutlined />}>{record.usersCount}</Tag>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      align: 'center',
      render: (record: TenantType) => (
        <Tag color={record.suspendedAt ? 'red' : 'green'}>
          {record.suspendedAt
            ? 'Suspended on ' + dayjs(record.suspendedAt).format('DD/MM/YYYY')
            : 'Active'}
        </Tag>
      ),
    },
    {
      key: 'createdAt',
      title: 'Created at',
      align: 'center',
      render: (record: TenantType) => (
        <Tag>{dayjs(record.createdAt).format('DD/MM/YYYY')}</Tag>
      ),
    },
    {
      key: 'ocr',
      title: 'OCR this month',
      align: 'center',
      render: (record: TenantType) => (
        <Tag icon={<FileOutlined />}>{record.invoiceOCRThisMonth}</Tag>
      ),
    },
    {
      key: 'storage',
      title: 'Storage',
      align: 'center',
      render: (record: TenantType) => (
        <Tag icon={<DatabaseOutlined />}>{record.filesSize}</Tag>
      ),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record: TenantType) => <></>,
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TenantType> | SorterResult<TenantType>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...extractSortDataFromSorterResult(sorter),
    }
    dispatch(setTenantQuery(newQuery))
    dispatch(getTenants(newQuery))
  }

  useEffect(() => {
    if (SBRMIsOpen) {
      return
    }
    // Trigger on init and when SBRM is closed
    dispatch(getTenants(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <>
      <PageTitle
        title="Tenants"
        badge={items.length}
        toolbar={
          <Space direction="horizontal">
            <Link to={'/tenants/attach-user'}>
              <Button>Attach user</Button>
            </Link>
            <Actions actions={['create']} entity={SBRMType.tenant} />
          </Space>
        }
      />
      <SBTable
        entity={SBRMType.tenant}
        scroll={{ x: 600 }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={items}
        pagination={query.pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
    </>
  )
}

export default TenantsPage
