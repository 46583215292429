import axios from 'axios'
import { AuthModel } from '../../models/AuthModel'
import { Admin } from '../../models/Admin'
import { APIResponse } from '../setupAxios'
import {
  GET_LOGGED_USER_INFOS,
  LOGIN_URL,
  LOGOUT_URL,
  REQUEST_PASSWORD_URL,
  RESET_PASSWORD_URL,
} from '../urls'

export function login(email: string, password: string) {
  const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID
  const OAUTH_CLIENT_SECRET = process.env.REACT_APP_OAUTH_CLIENT_SECRET

  return axios.post<AuthModel>(LOGIN_URL, {
    grant_type: 'password',
    client_id: OAUTH_CLIENT_ID,
    client_secret: OAUTH_CLIENT_SECRET,
    username: email,
    password,
    scope: '',
  })
}

export function logout() {
  return axios.post(LOGOUT_URL)
}

export function getLoggedAdminInfos() {
  return axios.get<APIResponse<Admin>>(GET_LOGGED_USER_INFOS)
}

export function updateLoggedAdminInfos(user: Admin) {
  return axios.put(GET_LOGGED_USER_INFOS, user)
}

export function forgotPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, { email: email })
}

export function resetPassword(email: string, token: string, password: string) {
  return axios.post(RESET_PASSWORD_URL, {
    email: email,
    token: token,
    password: password,
    password_confirmation: password,
  })
}
