import { Tenant } from '../../models/Tenant'
import { AuthModel } from '../../models/AuthModel'

const AUTH_LOCAL_STORAGE_TOKEN_KEY = 'alela-admin-auth-v1'
const AUTH_LOCAL_STORAGE_TENANT_KEY = 'alela-admin-tenant-v1'

const getLocalStorageAuth = <T>(key: string): T | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(key)
  if (!lsValue) {
    return
  }

  try {
    const auth: T = JSON.parse(lsValue) as T
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setLocalStorageAuth = <T>(key: string, auth: T): void => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(key, lsValue)
  } catch (error) {
    console.error(`AUTH LOCAL STORAGE SAVE ${key} ERROR`, error)
  }
}

const removeLocalStorageAuth = (key: string): void => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(key)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getAuth = () =>
  getLocalStorageAuth<AuthModel>(AUTH_LOCAL_STORAGE_TOKEN_KEY)

const setAuth = (auth: AuthModel) =>
  setLocalStorageAuth<AuthModel>(AUTH_LOCAL_STORAGE_TOKEN_KEY, auth)

const removeAuth = () => removeLocalStorageAuth(AUTH_LOCAL_STORAGE_TOKEN_KEY)

const getAuthTenant = () =>
  getLocalStorageAuth<Tenant>(AUTH_LOCAL_STORAGE_TENANT_KEY)

const setAuthTenant = (tenant: Tenant) =>
  setLocalStorageAuth<Tenant>(AUTH_LOCAL_STORAGE_TENANT_KEY, tenant)

const removeAuthTenant = () =>
  removeLocalStorageAuth(AUTH_LOCAL_STORAGE_TENANT_KEY)

export {
  getAuth,
  setAuth,
  removeAuth,
  getAuthTenant,
  setAuthTenant,
  removeAuthTenant,
}
