import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  condition: boolean
  wrapper: (children: React.ReactElement) => JSX.Element
  children: React.ReactElement
}
const ConditionalWrapper = ({ condition, wrapper, children }: Props) =>
  condition ? wrapper(children) : children

interface LinkProps {
  condition: boolean
  to: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  children: React.ReactElement
}
const ConditionalLinkWrapper = ({
  condition,
  to,
  onClick,
  children,
}: LinkProps) =>
  condition ? (
    <Link to={to} onClick={onClick}>
      {children}
    </Link>
  ) : (
    children
  )

export { ConditionalLinkWrapper, ConditionalWrapper }
