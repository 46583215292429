import { Button, Card, Form, message } from 'antd'
import PageTitle from '../../components/PageTitle'
import React, { useState } from 'react'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { SBAsyncSelect } from '../../components/custom-inputs/select/SBAsyncSelect'
import axios from 'axios'
import { TENANT_URL } from '../../utils/urls'

const AttachUserToTenantPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage()

  const attachUserToTenant = (values: any) => {
    axios
      .post(TENANT_URL + '/attach-user', {
        user: values.user,
        tenant: values.tenant,
      })
      .then(() => message.success('Yeah!'))
      .catch(() => message.success('Error...'))
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <PageTitle title="Attach user to tenant" />
      <Card bordered={false}>
        <Form
          onFinish={(values) => {
            if (!values.tenant || !values.user) return

            attachUserToTenant(values)
          }}
        >
          <SBAsyncSelect
            outsideSBRM
            type={SBRMType.tenant}
            name={'tenant'}
            label={'Tenant'}
          />
          <SBAsyncSelect
            outsideSBRM
            type={SBRMType.user}
            name={'user'}
            label={'User'}
          />
          <Button htmlType="submit" type="primary" loading={isLoading}>
            Go my friend
          </Button>
        </Form>
      </Card>
    </>
  )
}

export default AttachUserToTenantPage
