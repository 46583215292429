import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Layout, Space, Typography } from 'antd'

const { Footer } = Layout
const { Text } = Typography

export const LayoutFooter: React.FC = () => {
  const intl = useIntl()
  const year = new Date().getFullYear()

  return (
    <Footer className="hide-mobile">
      <Space style={{ justifyContent: 'center' }} className="w-full">
        <Link to={'/release-notes'}>
          <Text style={{ fontWeight: 300 }}>Release notes</Text>
        </Link>
        <Text style={{ fontWeight: 300 }}>© {year} Supplément Bacon</Text>
      </Space>
    </Footer>
  )
}
