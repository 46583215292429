import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
} from 'react'
import {
  getLoggedAdminInfos,
  logout as logoutAPI,
  updateLoggedAdminInfos,
} from './requests'
import { Admin } from '../../models/Admin'
import { useNavigate } from 'react-router-dom'
import { AuthModel } from '../../models/AuthModel'
import * as authHelper from '../helpers/AuthLocalStorageHelper'
import { Tenant } from '../../models/Tenant'
import { resetStore } from '../helpers/AuthHelper'
import { useAppDispatch } from '../../reducers/hooks'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentAdmin: Admin | undefined
  setCurrentAdmin: Dispatch<SetStateAction<Admin | undefined>>
  isLogged: boolean | undefined
  setIsLogged: Dispatch<SetStateAction<boolean | undefined>>
  currentTenant: Tenant | undefined
  saveTenant: (tenant: Tenant | undefined) => void
  logout: () => void
  updateAdmin: (user: Admin) => Promise<boolean>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentAdmin: undefined,
  setCurrentAdmin: () => {},
  isLogged: undefined,
  setIsLogged: () => {},
  currentTenant: undefined,
  saveTenant: () => {},
  logout: () => {},
  updateAdmin: ({}) => new Promise<boolean>(() => false),
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [currentAdmin, setCurrentAdmin] = useState<Admin | undefined>()
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined)
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentTenant, setCurrentTenant] = useState<Tenant | undefined>(
    authHelper.getAuthTenant()
  )

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const saveTenant = (tenant: Tenant | undefined) => {
    setCurrentTenant(tenant)
    if (tenant) {
      authHelper.setAuthTenant(tenant)
    } else {
      authHelper.removeAuthTenant()
    }
  }

  const logout = () => {
    // Whatever the request result
    // The user must be logged out
    logoutAPI().then(logoutHandler).catch(logoutHandler)
  }

  const logoutHandler = () => {
    setIsLogged(false)
    setCurrentAdmin(undefined)
    saveAuth(undefined)
    saveTenant(undefined)
    dispatch(resetStore())
    navigate('/auth/login')
  }

  const updateAdmin = (user: Admin) =>
    updateLoggedAdminInfos(user)
      .then((_) => {
        getLoggedAdminInfos().then((data) => setCurrentAdmin(data.data.data))
        return true
      })
      .catch((_) => false)

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentAdmin,
        setCurrentAdmin,
        isLogged,
        setIsLogged,
        currentTenant,
        saveTenant,
        logout,
        updateAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<PropsWithChildren> = ({ children }) => {
  const { auth, logout, setCurrentAdmin, setIsLogged, saveTenant } = useAuth()
  const didRequest = useRef(false)

  useEffect(() => {
    const requestAdmin = async () => {
      try {
        if (!didRequest.current) {
          const { data } = await getLoggedAdminInfos()
          if (data) {
            setCurrentAdmin(data.data)
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      setIsLogged(true)
      requestAdmin()
    } else {
      setIsLogged(false)
    }
  }, [])

  return <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
