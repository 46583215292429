import { useAuth } from '../utils/auth/Auth'
import { LoadingFullScreen } from './LoadingFullScreen'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const LayoutLogged: React.FC = () => {
  const { pathname } = useLocation()
  const { isLogged, currentAdmin } = useAuth()

  if (isLogged === false) {
    // We're not checking !isLogged as isLogged might be undefine during the account fetching on init
    // Ensure redirection to home page if the user is logged
    return <Navigate to={'/auth/login?returnTo=' + pathname} />
  }

  if (currentAdmin === undefined) {
    // We're not checking currentTenant as currentUser is set after
    // So when currentUser is not undefined it means the loading is finished
    return <LoadingFullScreen />
  }

  return <Outlet />
}

export default LayoutLogged
