import {
  Avatar,
  Button,
  Card,
  Divider,
  Menu,
  Modal,
  Space,
  Spin,
  Typography,
} from 'antd'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../utils/auth/Auth'
import { extractSectionFromPath } from '../../utils/helpers/RouterHelpers'
import { HistoryOutlined, ToolOutlined, UserOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

const ProfileSider = () => {
  const { logout: emailLogout, currentAdmin } = useAuth()

  const { pathname } = useLocation()
  const [selectedKey, setSelectedKey] = useState<string>('profile')
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false)
  const [logoutModalIsVisible, setLogoutModalIsVisible] =
    useState<boolean>(false)

  useEffect(() => {
    const menuItemSelected = extractSectionFromPath(pathname, 2)
    setSelectedKey(menuItemSelected)
  }, [pathname])

  return (
    <Card bodyStyle={{ padding: '30px 15px 15px 15px' }} bordered={false}>
      <Space direction="vertical" className="w-full">
        <Space direction="vertical" className="text-center w-full">
          <Avatar size={50} icon={<UserOutlined />} />
          <Space direction="vertical" size={0}>
            <Title level={4} style={{ margin: 0 }}>
              {currentAdmin?.name}
            </Title>
            <Text>{currentAdmin?.email}</Text>
            {!currentAdmin && <Spin />}
          </Space>
        </Space>
        <Divider style={{ margin: '20px 0 5px 0' }} />
        <Menu
          mode="vertical"
          selectedKeys={[selectedKey]}
          style={{ border: 0 }}
          items={[
            {
              key: 'profile',
              label: <Link to={'profile'}>Informations</Link>,
              icon: <UserOutlined />,
            },
            {
              key: 'activity',
              label: <Link to={'activity'}>Activity</Link>,
              icon: <HistoryOutlined />,
            },
            {
              key: 'preferences',
              label: <Link to={'preferences'}>Preferences</Link>,
              icon: <ToolOutlined />,
            },
          ]}
        />
        <Divider style={{ margin: '5px 0 2px 0' }} />
        <Button
          className="w-full"
          onClick={() => setLogoutModalIsVisible(true)}
          danger
          size="middle"
          loading={isLoggingOut}
        >
          Logout
        </Button>
      </Space>
      <Modal
        open={logoutModalIsVisible}
        onCancel={() => setLogoutModalIsVisible(false)}
        footer={
          <Button
            onClick={() => {
              setIsLoggingOut(true)
              emailLogout()
            }}
            loading={isLoggingOut}
            type="primary"
            color="danger"
          >
            Logout
          </Button>
        }
      >
        Are you sure to logout ?
      </Modal>
    </Card>
  )
}

export default ProfileSider
