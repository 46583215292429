import { File } from './File'
import { Link } from 'react-router-dom'
import { Card, Dropdown, MenuProps, Space, Tag, Typography } from 'antd'
import { FilePdfOutlined } from '@ant-design/icons'
import { File as FileMode } from '../../models/File'
import { useAppSelector } from '../../reducers/hooks'
import { selectFileById } from '../../reducers/FileReducer'
import EllipsisMiddle from '../utils/EllipsisMiddle'
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'

const { Text } = Typography

interface Props {
  id?: number
  model?: FileMode
  showDropdown?: boolean
  maxWidth?: number
}

export const FileCell = ({
  id,
  model,
  showDropdown = false,
  maxWidth = 100,
}: Props) => {
  const file = model ? model : useAppSelector(selectFileById(id ?? 0))

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: file && (
        <Actions
          actions={['view']}
          entity={SBRMType.file}
          entityId={file.id}
          trigger={'View'}
        />
      ),
      icon: <EyeOutlined />,
    },
    {
      key: '2',
      label: file && <Link to={file.downloadUrl}>Download</Link>,
      icon: <DownloadOutlined />,
    },
  ]

  return file ? (
    showDropdown ? (
      <Dropdown menu={{ items }}>
        <Tag style={{ cursor: 'pointer' }}>
          <Space direction="horizontal" style={{ padding: '5px 2px 3px 2px' }}>
            <FilePdfOutlined style={{ fontSize: 18 }} />
            <Space direction="vertical" size={0}>
              <EllipsisMiddle maxWidth={maxWidth} suffixCount={5}>
                {file.name}
              </EllipsisMiddle>
            </Space>
          </Space>
        </Tag>
      </Dropdown>
    ) : (
      <Space direction="horizontal">
        <FilePdfOutlined style={{ fontSize: 25, color: '#3c89e8' }} />
        <Space direction="vertical" size={0}>
          <EllipsisMiddle maxWidth={maxWidth} suffixCount={5}>
            {file.name}
          </EllipsisMiddle>
          <Text>{file.size}</Text>
        </Space>
      </Space>
    )
  ) : (
    <></>
  )
}

export type FileCellType = { Cell: typeof FileCell }
