import { Card, Typography } from 'antd'
import { useAuth } from '../../utils/auth/Auth'
import PageTitle from '../../components/PageTitle'

const ActivityPage = () => {
  const { currentAdmin } = useAuth()

  return (
    <>
      <PageTitle
        title="Activity"
        toolbar={<Typography.Text italic>20 last actions</Typography.Text>}
      />
      <Card bordered={false} bodyStyle={{ padding: 0 }}></Card>
    </>
  )
}

export default ActivityPage
