import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useAuth } from '../../utils/auth/Auth'
import { useNavigate } from 'react-router-dom'
import { emailRule, requiredRule } from '../../utils/rules'
import { getLoggedAdminInfos, login } from '../../utils/auth/requests'
import { Alert, Button, Checkbox, Col, Form, Input, Row } from 'antd'

const LoginPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { saveAuth, setCurrentAdmin, setIsLogged } = useAuth()

  const [emailData, setEmailData] = useState({ email: '', password: '' })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const onEmailLoginSubmit = async () => {
    setIsLoading(true)
    setError(undefined)
    try {
      const { data: auth } = await login(emailData.email, emailData.password)
      saveAuth(auth)

      const { data: user } = await getLoggedAdminInfos()
      setIsLogged(true)
      setCurrentAdmin(user.data)
      const queryParameters = new URLSearchParams(window.location.search)
      const returnTo = queryParameters.get('returnTo')
      if (returnTo) {
        navigate(returnTo)
      }
    } catch (error) {
      saveAuth(undefined)
      setError('Ensure your email/password is correct')
      setIsLoading(false)
    }
  }

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <img
          src={'/assets/images/logocurve.png'}
          height={45}
          style={{ marginTop: 12, marginLeft: 'auto', marginRight: 'auto' }}
        />
      </div>
      <>
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onEmailLoginSubmit}
          onValuesChange={(_, allFields) => setEmailData(allFields)}
        >
          <Form.Item name="email" rules={[requiredRule(intl), emailRule(intl)]}>
            <Input placeholder="Email" autoComplete="email" />
          </Form.Item>

          <Form.Item name="password" rules={[requiredRule(intl)]}>
            <Input.Password
              placeholder="Password"
              autoComplete="current-password"
            />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              className="w-full"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
        <Button
          className="w-full"
          type="text"
          onClick={() => navigate('/auth/forgot-password')}
        >
          Forgot password ?
        </Button>
      </>
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        {error && (
          <Alert style={{ marginBottom: 25 }} message={error} type="error" />
        )}
      </div>
    </>
  )
}

export default LoginPage
