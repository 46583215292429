import PDFViewer from './PDFViewer'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { SBRMType } from '../sbrm/SBRMModel'
import { File, isFile } from '../../models/File'
import { useAppSelector } from '../../reducers/hooks'
import { SBRMTypeInfos } from '../sbrm/SBRMTypeInfos'
import { Button, Card, Space, Typography } from 'antd'

const { Text } = Typography

interface Props {
  entity: SBRMType
  style?: React.CSSProperties
}

const FileViewer = ({ entity, style }: Props) => {
  const [resourceFile, setResourceFile] = useState<File | undefined>(undefined)

  // Get selected resource based on the entity
  const resourceId = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].selectedId
  )
  const resource = useAppSelector(
    (state) => state[SBRMTypeInfos[entity].reducerName].items[resourceId!]
  )
  const { items: files } = useAppSelector((state) => state.file)
  const { isOpen: SBRMisOpen } = useAppSelector((state) => state.SBRM)

  const extractFileFromArg = (arg: any): File | undefined => {
    if (isFile(arg)) {
      // The file property conforms the File interface
      // Bingo! We have a file, let's return it
      return arg
    } else if (Array.isArray(arg) && arg[0]) {
      // We have an array
      // Let's consider only the first item
      return extractFileFromArg(arg[0])
    } else if (typeof arg === 'number') {
      // We have a number
      // Let's check if we have a file with a matching ID in store
      return files[arg]
    }
    return undefined
  }

  // Get the file attached to the resource
  const getFile = () => {
    if (typeof SBRMTypeInfos[entity].filePropertyName === 'string') {
      // Grab the resource property in a variable
      const fileProperty = (resource as Record<string, any>)[
        `${SBRMTypeInfos[entity].filePropertyName}`
      ]

      return extractFileFromArg(fileProperty)
    }

    if (SBRMTypeInfos[entity].filePropertyName === null) {
      return resource as unknown as File
    }

    return undefined
  }

  useEffect(() => {
    if (!SBRMisOpen) return // When closing SBRM do not set the resource
    setResourceFile(getFile())
  }, [SBRMisOpen, resource])

  useEffect(() => {
    /**
     * Reset the selected resource when the Drawer is closed
     */
    if (!SBRMisOpen) {
      setResourceFile(undefined)
    }
  }, [SBRMisOpen])

  return (
    <>
      {resourceFile && resourceFile.extension === 'pdf' && (
        <PDFViewer
          file={resourceFile}
          displayThumbnails={false}
          handleSuccessLoaded={() => {}}
        />
      )}
      {resourceFile && resourceFile.extension !== 'pdf' && (
        <Card
          bordered={false}
          style={{
            width: '300px',
            top: 'calc(50vh - 57px)',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <Space
            direction="vertical"
            style={{ textAlign: 'center', width: '100%' }}
            size={20}
          >
            <img src={'/assets/images/file-download.svg'}></img>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text strong>{resourceFile?.name}</Text>
            </Space>
            <Link
              to={resourceFile!.downloadUrl}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <Button size="large" className="w-full" type="primary">
                Download
              </Button>
            </Link>
          </Space>
        </Card>
      )}
    </>
  )
}

export default FileViewer
