import { Navigate, Route, Routes } from 'react-router-dom'
import SettingsPage from '../../pages/settings/SettingsPage'
import { LayoutSettings } from '../../layouts/LayoutSettings'

const SettingsRoutes = () => {
  return (
    <Routes>
      <Route element={<LayoutSettings />}>
        <Route index path="settings" element={<SettingsPage />} />
        <Route path="*" element={<Navigate to={'settings'} />} />
      </Route>
    </Routes>
  )
}

export default SettingsRoutes
