import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Button, Form, Input, Result } from 'antd'
import { resetPassword } from '../../utils/auth/requests'
import {
  capsRule,
  containsNumberRule,
  containsSymbolRule,
  emailRule,
  lowercaseRule,
  minLengthRule,
  requiredRule,
} from '../../utils/rules'

const ResetPasswordPage = () => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [data, setData] = useState({ email: '', token: '', password: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [hasError, setHasError] = useState(false)

  const onFinish = async () => {
    setIsLoading(true)
    setHasError(false)
    try {
      await resetPassword(data.email, data.token, data.password)
      setIsSuccess(true)
    } catch (error) {
      setHasError(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const email = queryParameters.get('email')
    const token = queryParameters.get('token')
    if (!email || !token) {
      setHasError(true)
      true
    }

    const nextData = { email: email!, token: token! }
    setData({ ...data, ...nextData })
    form.setFieldsValue(nextData)
  }, [])

  return isSuccess ? (
    <Result
      status="success"
      title="Great now you can login"
      extra={[
        <Button
          key={'back-login'}
          type="primary"
          onClick={() => navigate('/auth/login')}
        >
          Back to login
        </Button>,
      ]}
    />
  ) : (
    <>
      <div style={{ textAlign: 'center' }}>
        <img
          src={'/assets/images/logocurve.png'}
          height={45}
          style={{ marginTop: 12, marginLeft: 'auto', marginRight: 'auto' }}
        />
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ remember: true }}
        onValuesChange={(_, allFields) => setData({ ...data, ...allFields })}
      >
        <Form.Item name="email" rules={[requiredRule(intl), emailRule(intl)]}>
          <Input placeholder="Email" disabled />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            requiredRule(intl),
            minLengthRule(8, intl),
            lowercaseRule(intl),
            capsRule(intl),
            containsNumberRule(intl),
            containsSymbolRule(intl),
          ]}
        >
          <Input placeholder="Password" type="password" />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            className="w-full"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Reset password
          </Button>
        </Form.Item>
      </Form>
      {hasError && (
        <Alert
          style={{ marginTop: 25 }}
          message="Impossible to reset password"
          type="error"
        />
      )}
    </>
  )
}

export default ResetPasswordPage
