import { App } from 'antd'
import { Navigate, Route, Routes } from 'react-router-dom'
import TenantsPage from '../../pages/tenants/TenantsPage'
import TenantPage from '../../pages/tenants/TenantPage'
import AttachUserToTenantPage from '../../pages/tenants/AttachUserToTenantPage'

const TenantRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route path="overview" element={<TenantsPage />} />
        <Route path="attach-user" element={<AttachUserToTenantPage />} />
        <Route path=":tenantId" element={<TenantPage />} />
        <Route index path="*" element={<Navigate to={'overview'} />} />
      </Route>
    </Routes>
  )
}

export default TenantRoutes
