import { SBRMType } from './SBRMModel'
import { SBRMAction } from './SBRMModel'

const titles = {
  [SBRMType.file]: {
    ['view' as SBRMAction]: 'View file',
    ['create' as SBRMAction]: 'Upload file',
    ['update' as SBRMAction]: 'Edit file',
    ['delete' as SBRMAction]: 'Delete file',
  },
  [SBRMType.user]: {
    ['create' as SBRMAction]: 'Create new user',
    ['update' as SBRMAction]: 'Edit user',
    ['delete' as SBRMAction]: 'Delete user',
  },
  [SBRMType.role]: {
    ['create' as SBRMAction]: '',
    ['update' as SBRMAction]: '',
    ['delete' as SBRMAction]: '',
  },
  [SBRMType.tenant]: {
    ['create' as SBRMAction]: 'Add new tenant',
    ['update' as SBRMAction]: 'Update tenant',
    ['delete' as SBRMAction]: 'Delete tenant',
  },
  [SBRMType.banner]: {
    ['create' as SBRMAction]: 'Add new banner',
    ['update' as SBRMAction]: 'Update banner',
    ['delete' as SBRMAction]: 'Delete banner',
  },
}

export const getSBRMTitle = (
  entity: SBRMType | undefined,
  action: SBRMAction | undefined
): string =>
  entity == undefined || action == undefined ? 'Error' : titles[entity][action]
