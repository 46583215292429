import { resetAddressState } from '../../reducers/AddressReducer'
import { resetFileState } from '../../reducers/FileReducer'
import { resetFileTypeState } from '../../reducers/FileTypeReducer'
import { resetPermissionState } from '../../reducers/PermissionReducer'
import { resetSBRM } from '../../reducers/SBRMReducer'
import { resetAdminState } from '../../reducers/UserReducer'
import { AppDispatch } from '../../reducers/store'

/**
 * Reset Redux store to its initial state
 * Must be dispatched!
 */
const resetStore = () => (dispatch: AppDispatch) => {
  dispatch(resetAddressState())
  dispatch(resetFileState())
  dispatch(resetFileTypeState())
  dispatch(resetPermissionState())
  dispatch(resetSBRM())
  dispatch(resetAdminState())
}

export { resetStore }
