import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIDelete,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import axios from 'axios'
import { Admin } from '../models/Admin'
import { Role } from '../models/Role'
import { userSchema } from '../models/schema'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { ACCOUNT_USERS_URL, USER_ROLE_URL } from '../utils/urls'

const initialState: SBState<Admin> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getAdminsSuccess,
  setQuery: setAdminQuery,
  reset: resetAdminState,
  resetQueryAndIds: resetAdminQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Admin }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectAdmins = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedAdmin = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectAdminById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectAdminsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedAdmin =
  (user: Admin | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Admin>(user)))

export const getAccountAdmins = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Admin>(
    ACCOUNT_USERS_URL,
    params,
    [userSchema],
    slice.actions
  )

export const getAccountAdminWithId = (id: number) =>
  SBAPIFetchDispatch<Admin>(
    `${ACCOUNT_USERS_URL}/${id}`,
    userSchema,
    slice.actions
  )

export const inviteAdmin = (user: Admin) =>
  SBAPICreate<Admin>(user, ACCOUNT_USERS_URL, userSchema, slice.actions)

export const updateAdminRole =
  (user: Admin, role: Role) => async (dispatch: AppDispatch) => {
    const oldAdmin = Object.assign({}, user)
    dispatch(slice.actions.updateItemSuccess({ ...user, roles: [role] }))
    await axios
      .put(`${USER_ROLE_URL}/${user.id}/roles`, { role: role.id })
      .catch((error) => {
        dispatch(slice.actions.updateItemSuccess(oldAdmin))
        throw error
      })
  }

export const associateContact =
  (user: Admin, contactId: number) => async (dispatch: AppDispatch) => {
    const oldAdmin = Object.assign({}, user)
    dispatch(slice.actions.updateItemSuccess({ ...user, contact: contactId }))
    await axios
      .post(`${ACCOUNT_USERS_URL}/${user.id}/associate-contact`, {
        contact: contactId,
      })
      .catch((error) => {
        dispatch(slice.actions.updateItemSuccess(oldAdmin))
        throw error
      })
  }
