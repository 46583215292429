import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
} from '../utils/helpers/SBAPIHelper'
import { Tenant } from '../models/Tenant'
import { TENANT_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { tenantSchema } from '../models/schema'

const initialState: SBState<Tenant> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'tenant',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getTenantsSuccess,
  setQuery: setTenantQuery,
  reset: resetTenantState,
  resetQueryAndIds: resetTenantQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Tenant }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectTenants = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedTenant = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectTenantById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectTenantsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedTenant =
  (tenant: Tenant | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Tenant>(tenant)))

export const getTenants = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Tenant>(
    TENANT_URL,
    params,
    [tenantSchema],
    slice.actions
  )
export const getTenantWithId = (id: Number) =>
  SBAPIFetchDispatch<Tenant>(`${TENANT_URL}/${id}`, tenantSchema, slice.actions)

export const createTenant = (tenant: Tenant) =>
  SBAPICreate<Tenant>(tenant, TENANT_URL, tenantSchema, slice.actions)
