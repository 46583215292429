import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Form, FormInstance, Input } from 'antd'
import { requiredRule } from '../../../utils/rules'
import { useAppSelector } from '../../../reducers/hooks'
import { selectSelectedFile } from '../../../reducers/FileReducer'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const intl = useIntl()
  const file = useAppSelector(selectSelectedFile())

  useEffect(() => {
    form.setFieldsValue(file)
  }, [file])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        style={{ display: 'none' }}
        name="id"
        required
        rules={[requiredRule(intl)]}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="name"
        required
        label={'Name'}
        rules={[requiredRule(intl)]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default Update
