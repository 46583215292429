import { Card } from 'antd'
import PageTitle from '../../components/PageTitle'

const SettingsPage = () => {
  return (
    <>
      <PageTitle title="Home page" />
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        ...
      </Card>
    </>
  )
}

export default SettingsPage
