import { Badge, Card, Col, Row, Statistic } from 'antd'
import PageTitle from '../components/PageTitle'

const MetricsPage = () => {
  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <PageTitle title="Overviews" />
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Card>
              <Statistic title="Mindee this month" value={'XXXX'} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Stockage this month" value={'XXXX'} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Mailgun request this month" value={'XXXX'} />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MetricsPage
