import {
  Alert,
  AlertProps,
  Badge,
  Button,
  Card,
  Col,
  Form,
  Row,
  Statistic,
  Switch,
  Tag,
  Tooltip,
} from 'antd'
import PageTitle from '../components/PageTitle'
import SBTable from '../components/SBTable'
import { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import { Banner as BannerType } from '../models/Banner'
import { SBRMType } from '../modules/sbrm/SBRMModel'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import {
  getBanners,
  selectBanners,
  setBannerQuery,
} from '../reducers/BannerReducer'
import { useAppDispatch, useAppSelector } from '../reducers/hooks'
import { useEffect } from 'react'
import { Tenant } from '../components/tenant/Tenant'
import Marquee from 'react-fast-marquee'
import { Controls } from '../components/controls/Controls'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Actions } from '../modules/sbrm/components/Actions'

const ControlsPage = () => {
  const dispatch = useAppDispatch()

  const items = useAppSelector(selectBanners())
  const { query, isLoading } = useAppSelector((state) => state.banner)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const columns: ColumnsType<BannerType> = [
    {
      key: 'id',
      title: 'Id',
      render: (record: BannerType) => <div>{record.id}</div>,
    },
    {
      key: 'tenant',
      title: (
        <>
          Tenant
          <Tooltip title="Banners not attached to a tenant will be displayed to all tenants">
            <Button
              size="small"
              shape="circle"
              icon={<QuestionCircleOutlined />}
            />
          </Tooltip>
        </>
      ),
      render: (record: BannerType) => <Tenant.Avatar id={record.tenant} />,
    },
    {
      key: 'message',
      title: 'message',
      render: (record: BannerType) => (
        <Alert
          type={record.type as AlertProps['type']}
          banner
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: record.message,
              }}
            ></div>
          }
        />
      ),
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (record: BannerType) => (
        <Actions
          entityId={record.id}
          entity={SBRMType.banner}
          actions={['delete']}
        />
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<BannerType> | SorterResult<BannerType>[]
  ) => {
    const newQuery = {
      pagination,
      filters,
      ...sorter,
    }
    dispatch(setBannerQuery(newQuery))
    dispatch(getBanners(newQuery))
  }

  useEffect(() => {
    if (SBRMIsOpen) {
      return
    }
    // Trigger on init and when SBRM is closed
    dispatch(getBanners(query))
  }, [dispatch, SBRMIsOpen])

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <PageTitle title="Controls" />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Controls.MaintenanceMode />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <PageTitle
          title="Banners"
          toolbar={<Actions entity={SBRMType.banner} actions={['create']} />}
        />
        <SBTable
          entity={SBRMType.banner}
          scroll={{ x: 600 }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={items}
          pagination={query.pagination}
          loading={isLoading}
          onChange={handleTableChange}
        />
      </Col>
    </Row>
  )
}

export default ControlsPage
