import { FC } from 'react'
import { FileCard, FileCardType } from './Card'
import { FileUpload, FileUploadType } from './Upload'
import { FileCell, FileCellType } from './Cell'
import { FileGrid, FileGridType } from './Grid'
import { FileDropzone, FileDropzoneType } from '../custom-inputs/Dropzone'
import { DownloadButton, DownloadButtonType } from './DownloadButton'

export const File: FC &
  FileCardType &
  FileCellType &
  FileGridType &
  FileUploadType &
  DownloadButtonType &
  FileDropzoneType = (): JSX.Element => <></>

File.Card = FileCard
File.Cell = FileCell
File.Grid = FileGrid
File.Upload = FileUpload
File.Dropzone = FileDropzone
File.DownloadButton = DownloadButton
