import { Link } from 'react-router-dom'
import { useAuth } from '../../utils/auth/Auth'
import { Avatar, Dropdown, MenuProps } from 'antd'

const RightHeader = () => {
  const { currentAdmin } = useAuth()

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      label: <Link to={'/account/profile'}>Profile</Link>,
    },
    {
      key: 'settings',
      label: <Link to={'/settings'}>Settings</Link>,
    },
  ]

  return (
    <>
      <Dropdown menu={{ items }} placement="bottomLeft" arrow>
        <Avatar
          style={{
            color: '#000',
            backgroundColor: '#FFF',
            cursor: 'pointer',
          }}
        >
          {currentAdmin && currentAdmin.name
            ? currentAdmin.name.slice(0, 1)
            : '--'}
        </Avatar>
      </Dropdown>
    </>
  )
}

export default RightHeader
