import { useEffect, useRef, useState } from 'react'
import { MetaData } from '../../models/MetaData'
import { SBRMAction, SBRMFormInterface, SBRMType } from './SBRMModel'
import { useAppSelector } from '../../reducers/hooks'
import { SBRMState } from '../../reducers/SBRMReducer'

// Form imports
import FileForm from './file/FileForm'
import UserForm from './user/UserForm'
import BannerForm from './banner/BannerForm'
import TenantForm from './tenant/TenantForm'

type useSBRMformsProps = {
  isNested: boolean
}
const useSBRMforms = ({
  isNested,
}: useSBRMformsProps): {
  refs: Record<SBRMType, React.RefObject<SBRMFormInterface>>
  forms: Record<SBRMType, JSX.Element>
} => {
  const SBRMrefs = {
    [SBRMType.file]: useRef<SBRMFormInterface>(null),
    [SBRMType.user]: useRef<SBRMFormInterface>(null),
    [SBRMType.role]: useRef<SBRMFormInterface>(null),
    [SBRMType.tenant]: useRef<SBRMFormInterface>(null),
    [SBRMType.banner]: useRef<SBRMFormInterface>(null),
  }

  const SBRMforms = {
    [SBRMType.file]: (
      <FileForm ref={SBRMrefs[SBRMType.file]} isNested={isNested} />
    ),
    [SBRMType.user]: (
      <UserForm ref={SBRMrefs[SBRMType.user]} isNested={isNested} />
    ),
    [SBRMType.role]: <></>,
    [SBRMType.tenant]: (
      <TenantForm ref={SBRMrefs[SBRMType.tenant]} isNested={isNested} />
    ),
    [SBRMType.banner]: (
      <BannerForm ref={SBRMrefs[SBRMType.banner]} isNested={isNested} />
    ),
  }
  return { refs: SBRMrefs, forms: SBRMforms }
}

const useSBRMData = (isNested: boolean): SBRMState => {
  const {
    isOpen: SBRMisOpen,
    entity: SBRMentity,
    action: SBRMaction,
    metadata: SBRMmetadata,
  } = useAppSelector((state) => state.SBRM)
  const {
    isOpen: SBRMnestedIsOpen,
    entity: SBRMnestedEntity,
    action: SBRMnestedAction,
    metadata: SBRMnestedMetadata,
  } = useAppSelector((state) => state.SBRM.nested)

  return {
    isOpen: isNested ? SBRMnestedIsOpen : SBRMisOpen,
    entity: isNested ? SBRMnestedEntity : SBRMentity,
    action: isNested ? SBRMnestedAction : SBRMaction,
    metadata: SBRMmetadata,
  }
}

export { useSBRMforms, useSBRMData }
