import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../utils/helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIDelete,
  SBAPIFetchDispatch,
  SBAPIFetchPaginatedDispatch,
  SBAPIUpdate,
} from '../utils/helpers/SBAPIHelper'
import { Banner } from '../models/Banner'
import { BANNER_URL } from '../utils/urls'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { bannerSchema } from '../models/schema'

const initialState: SBState<Banner> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'banner',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getBannersSuccess,
  setQuery: setBannerQuery,
  reset: resetBannerState,
  resetQueryAndIds: resetBannerQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Banner }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectBanners = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedBanner = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectBannerById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectBannersByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedBanner =
  (banner: Banner | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Banner>(banner)))

export const getBanners = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Banner>(
    BANNER_URL,
    params,
    [bannerSchema],
    slice.actions
  )
export const getBannerWithId = (banner: Banner) =>
  SBAPIFetchDispatch<Banner>(
    `${BANNER_URL}/${banner.id}`,
    bannerSchema,
    slice.actions
  )

export const createBanner = (data: Banner) =>
  SBAPICreate<Banner>(data, BANNER_URL, bannerSchema, slice.actions)

export const deleteBanner = (banner: Banner) =>
  SBAPIDelete<Banner>(banner, `${BANNER_URL}/${banner.id}`, slice.actions)
