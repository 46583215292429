import { useAuth } from '../utils/auth/Auth'
import { Navigate, Outlet } from 'react-router-dom'
import { Card, Col, Layout, Row, Space, Typography, theme } from 'antd'

const { Text } = Typography
const { Footer, Content } = Layout

export const AuthLayout: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  const { isLogged } = useAuth()

  if (isLogged) {
    // Ensure redirection to home page if the user is logged
    return <Navigate to={'/'} />
  }

  const year = new Date().getFullYear()

  return (
    <Layout className="min-h-100vh">
      <Content>
        <Row className="min-h-100vh">
          <Col
            className="min-h-100vh hide-mobile"
            md={12}
            sm={24}
            style={{
              background: "url('/assets/images/login-background.jpeg')",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></Col>
          <Col
            className="min-h-100vh"
            md={12}
            sm={24}
            xs={24}
            style={{
              display: 'flex',
              height: '100% ',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                marginTop: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Card
                bordered={false}
                bodyStyle={{ paddingBottom: 20 }}
                style={{ minWidth: 320, maxWidth: 400, margin: 'auto' }}
              >
                <Outlet />
              </Card>
            </div>
            <div
              className="text-center"
              style={{
                marginTop: 'auto',
              }}
            >
              <Footer>
                <Space>
                  <a
                    type="text"
                    className="ant-btn-text"
                    style={{
                      textAlign: 'center',
                      color: 'rgba(255, 255, 255, 0.85)',
                    }}
                    href="mailto:hello@supplement-bacon.com?subject=Alela interest"
                  >
                    No account ? Contact us
                  </a>
                  <Text style={{ fontWeight: 300 }}>
                    © {year} Supplément Bacon
                  </Text>
                </Space>
              </Footer>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}
